<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Datepicker from "vue3-datepicker";
import axios from 'axios'

/**
 * Email-read component
 */
export default {
    setup() {
    },
    components: {
        Layout,
        PageHeader,
        Datepicker
    },
    data() {
        return {
            title: "Liste des Modifications",
            items: [
                {
                    text: "Updates",
                    href: "/sosListe"
                },
                {
                    text: "Updates Liste",
                    active: true
                }
            ],
            responseData: [],
            receiveResponsesList: [],
            fetchError: null,
            response: null
        };
    },
    created() {
    },
    methods: {
        ...reqMethods,
        searchFilter(e) {
            const searchStr = e.target.value;
            this.responseData = this.receiveResponsesList.filter((alert) => {
                return (
                    alert.titre.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematique.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.author.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.zone.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.titre.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },
        dateFilter() {

        },
        async getThisSosUpdates() {
            const that = this;
            try {
                const response = await axios.get(`https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/Update/?id=${parseInt(this.$route.params.id)}`,
                    {
                        headers: {
                            'Authorization': localStorage.getItem('token'),
                        },
                    })
                 that.responseData = response.data;
                 console.log(that.responseData);
            } catch (error) {
                that.fetchError = error;
            }
        }
    },
    mounted() {
        this.getThisSosUpdates();
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." @input="searchFilter($event)" />
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6 justify-content-end">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                                    placeholder="Date">
                                </datepicker>
                            </div>
                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info" @click="dateFilter">
                                    <i class="bx bx-slider align-middle me-1"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-3">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th>Référence</th>
                                    <th>Auteur</th>
                                    <th>Téléphone</th>
                                    <th scope="col">Titre d'alerte</th>
                                    <th scope="col">Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <!-- v-for="alert in responseData" :key=alert.id -->
                                    <td> alert.id </td>
                                    <td> alert.author.name </td>
                                    <td> alert.author.phone </td>
                                    <td> alert.titre </td>
                                    <td> alert.date </td>
                                    <td>
                                        <button @click="$router.push({ path: `/replyDetails/1` })"
                                            class="btn btn-info btn-sm me-2 w-xs">
                                            Lire
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
